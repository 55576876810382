<template>
  <div class="step-lesson-tabs">
    <app-tabs :tabs="tabs" v-on:tab-changed="onTabChanged">
      <template v-slot:tab="{ name, label, isCurrent }">
        <div :key="'tab-' + name"
           class="step-lesson-tab"
           :class="{ current: isCurrent }"
        >
          {{ label }}
        </div>
      </template>
    </app-tabs>
  </div>
</template>

<script>
import AppTabs from '@/components/AppTabs'

export default {
  name: 'StepLessonTabs',
  components: { AppTabs },
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  methods: {
    onTabChanged () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss">
.step-lesson-tabs {
  width: 100%;
  > .app-tabs {
    > .tabs-wrapper {
      background-color: $c-text;
      > .tabs {
        max-width: $bp-desktop-big;
        margin: 0 auto;
        display: flex;
        align-items: flex-end;
        height: 59px;
        > .tab-wrapper + .tab-wrapper {
          margin-left: 1px;
        }
      }
    }
    .step-lesson-tab {
      height: 44px;
      transition: height 0.2s, $color-transition;
      color: #fff;
      background-color: rgba(#fff, .5);
      text-transform: uppercase;
      font-size: $fz-xs;
      font-weight: $fw-m;
      padding: 0 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: $radius-s;
      border-top-right-radius: $radius-s;
      &.current {
        font-weight: $fw-l;
        color: $c-text;
        opacity: 1;
        height: 54px;
        background-color: $c-background;
      }
    }
  }
}

@media (min-width: $bp-tablet) {
  .step-lesson-tabs {
    > .app-tabs {
      > .tabs-wrapper {
        > .tabs {
          justify-content: center;
        }
      }
    }
  }
}
</style>
