<template>
  <div class="app-tabs">
    <div class="tabs-wrapper">
      <div class="tabs">
        <div v-for="tab in tabs"
          :key="'tab-' + tab.name"
          class="tab-wrapper"
          :class="{ current: isCurrentTab(tab.name) }"
          @click="setTab(tab.name)"
        >
          <slot name="tab" v-bind="tab" :is-current="isCurrentTab(tab.name)">
            {{ tab.label }}
          </slot>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <slot name="content" v-bind="getCurrentTab">
        <component :is="currentTabComponent" :key="'tab-content-' + currentTab" @next-tab="goToNextTab"></component>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import SimpleLoading from '@/components/SimpleLoading'

export default {
  name: 'AppTabs',
  emits: ['tab-changed'],
  props: {
    tabs: {
      type: Array,
      required: true
    },
    disableDefaultTab: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      currentTab: this.disableDefaultTab ? '' : this.tabs[0].name
    }
  },
  computed: {
    getCurrentTab () {
      return this.disableDefaultTab && this.currentTab === ''
        ? { name: '' }
        : this.tabs.find(tab => tab.name === this.currentTab)
    },
    currentTabComponent () {
      if (this.getCurrentTab === null || this.getCurrentTab.component === undefined) return
      return defineAsyncComponent({
        loader: this.getCurrentTab.component,
        loadingComponent: SimpleLoading
      })
    }
  },
  methods: {
    isCurrentTab (tabName) {
      return this.currentTab === tabName
    },
    setTab (tabName) {
      this.currentTab = tabName
      this.$emit('tab-changed')
    },
    goToNextTab () {
      const currentTabIndex = this.tabs.findIndex(tab => tab.name === this.currentTab)
      if (currentTabIndex !== -1) {
        this.setTab(this.tabs[currentTabIndex + 1].name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-tabs {
  .tabs-wrapper {
    .tab-wrapper {
      cursor: pointer;
      &.current {
        cursor: default;
      }
    }
  }
}
</style>

<style lang="scss">
.app-tabs {
  .tab-content {
    .simple-loader {
      margin: $space-xxl auto;
      .loader-icon {
        svg {
          circle {
            stroke: $c-secondary;
          }
        }
      }
    }
  }
}
</style>
